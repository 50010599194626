/* eslint-disable no-nested-ternary */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxListOnCallRequest } from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { translator } from '~/presentation/components/i18n';
import { NavigationBar } from '~/presentation/components/navigationBar';
import { closeModal } from '~/utils/closeModal';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { Finished } from './Finished';
import { FollowUp } from './FollowUp';
import { WaitingService } from './Waiting';
import { Container, ListHeader, TableContainer } from './styles';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';

type Row = iOnCallRequest['records'][0];

type SelectedRequest = Row | null;

type PatientDutyListProps = {
  selectedRequest: SelectedRequest;
  setSelectedRequest: (requester: SelectedRequest) => void;
  variant?: 'REQUESTER' | 'PROFESSIONAL';
  healthUnitId?: number;
};

const PatientDutyList: React.FC<PatientDutyListProps> = ({
  selectedRequest,
  setSelectedRequest,
  variant,
  healthUnitId,
}) => {
  const { state, pathname } = useLocation<{
    specialty: string;
    id: string;
    leaveConf: boolean;
  }>();

  const last = pathname.split('/').pop();
  const id = last === 'me' ? undefined : Number(last);

  const labels: string[] = [
    translator('AGUARDANDO ATENDIMENTO'),
    translator('EM ACOMPANHAMENTO'),
    translator('FINALIZADOS'),
  ];

  const [select, setSelect] = useState<string>(labels[0]);

  const professional = getProfessionalInfo();

  useEffect(() => {
    if (variant === 'REQUESTER' && !state?.leaveConf)
      makeReduxActiveMessage().active({
        active: 'isProfessionalAvailable',
        actionOk: () => {
          closeModal();
        },
        data: {
          specialty: state?.specialty,
          id: state?.id,
        },
        actionCancel: () => {
          closeModal();
        },
      });
  }, [state]);

  useEffect(() => {
    makeReduxListOnCallRequest().list({
      body: {
        dataControl: {
          limit: 9999,
        },
        filters: {
          requester: variant === 'PROFESSIONAL' ? professional?.id : undefined,
          onCall: id,
        },
      },
    });
  }, [professional?.id, select, id]);

  function handleSelect(key: string) {
    setSelect(key);
  }

  return (
    <Container>
      <ListHeader>
        <NavigationBar
          active={select}
          labels={labels}
          clickLabel={handleSelect}
        />
      </ListHeader>
      <TableContainer>
        {labels.indexOf(select) === 0 && (
          <WaitingService
            selectedRequest={selectedRequest}
            setSelectedRequest={setSelectedRequest}
            variant={variant}
            healthUnitId={healthUnitId}
          />
        )}
        {labels.indexOf(select) === 1 && (
          <FollowUp
            selectedRequest={selectedRequest}
            setSelectedRequest={setSelectedRequest}
            variant={variant}
            healthUnitId={healthUnitId}
          />
        )}
        {labels.indexOf(select) === 2 && (
          <Finished
            selectedRequest={selectedRequest}
            setSelectedRequest={setSelectedRequest}
            variant={variant}
            healthUnitId={healthUnitId}
          />
        )}
      </TableContainer>
    </Container>
  );
};

export default PatientDutyList;
